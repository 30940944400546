import { reactive } from 'vue';
import api from '@/api';
import { RoleSelector } from '@/types/RoleType';

export default function useRoles() {
  const roles = reactive({
    options: [] as any,
    name: '' as string,
    async fetch() {
      try {
        const { data } = await api.user.roles();
        roles.options = data
          .filter(({ value }: { value: string }) => !['EXECUTOR', 'ADMINMOTIV'].includes(value))
          .map(({ label, value, actions }: RoleSelector) => ({ value, label, actions }));
      } catch (e) {
        console.error(e);
      }
    },
  });

  return { roles };
}
