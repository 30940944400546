
import {
  computed, defineComponent, ref, watch,
} from 'vue';
import XLSX, { WorkSheet } from 'xlsx';
import Modal from '@/components/UI/Modal/index.vue';
import api from '@/api';
import { UserFromFile } from '@/types/UploadFiles';

export default defineComponent({
  components: {
    Modal,
  },
  setup(props, ctx) {
    const cols = [
      {
        label: 'Код',
        field: 'Код',
      },
      {
        label: 'Фамилия',
        field: 'Фамилия',
      },
      {
        label: 'Имя',
        field: 'Имя',
      },
      {
        label: 'Отчество',
        field: 'Отчество',
      },
      {
        label: 'Должность',
        field: 'Должность',
      },
      {
        label: 'Номер',
        field: 'Номер',
      },
      {
        label: 'ИНН',
        field: 'ИНН',
      },
      {
        label: 'Роль',
        field: 'Роль',
      },
      {
        label: 'Логин',
        field: 'Логин',
      },
      {
        label: 'Пароль',
        field: 'Пароль',
      },
    ];

    const file = ref<File | null>(null);
    const decodedFile = ref<{[key: string]: WorkSheet} | null>(null);

    const label = computed(() => {
      if (file.value) return `${file.value.name.slice(0, 20)}...`;
      return 'Обзор...';
    });

    function decodeFile() {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const data = e?.target?.result;
        if (data) {
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          let result: WorkSheet | null = null;
          const sheetName = workbook.SheetNames[0];
          const roa: UserFromFile[] = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 0 });
          if (roa.length) {
            result = roa;
          } else result = null;
          decodedFile.value = result;
        }
      };
      fileReader.readAsBinaryString(file.value as Blob);
    }

    watch(file, (val) => {
      if (val) {
        if (val) decodeFile();
      }
    });

    async function onClickDownloadTpl() {
      await api.user.downloadTemplate();
    }

    const loading = ref(false);
    async function onSave() {
      try {
        loading.value = true;
        await api.user.loadfile(file.value as File);
        // eslint-disable-next-line no-alert
        alert('Данные успешно загружены');
        ctx.emit('close');
      } catch (e) {
        // eslint-disable-next-line no-alert
        alert(`Ошибка при добавлении пользователей.\n${e?.response?.data || 'Неизвестная ошибка'}`);
      } finally {
        loading.value = false;
      }
    }

    return {
      file,
      label,
      loading,
      onClickDownloadTpl,
      onSave,
      cols,
      decodedFile,
    };
  },
});
