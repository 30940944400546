
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/UI/Modal/index.vue';
import Preloader from '@/components/UI/Preloader.vue';

export default defineComponent({
  name: 'editUsers',
  components: {
    Modal,
    Preloader,
  },
  props: {
    inspector: {
      type: Object as PropType<{
        title: string,
        data: Worker & { password?: string },
        error: string,
        handleRequest: boolean,
        display: boolean,
      }>,
      required: true,
    },
    modelValue: {
      type: Object as PropType<Worker & { password?: string }>,
      required: true,
    },
  },
});
