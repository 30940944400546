
import { defineComponent, PropType } from 'vue';
import Modal from '@/components/UI/Modal/index.vue';
import Preloader from '@/components/UI/Preloader.vue';
import { IObjectCount } from '@/types/Statistic';
import { multiSelectorList } from '@/components/UI/Table/types';

export default defineComponent({
  name: 'removeUsers',
  components: {
    Modal, Preloader,
  },
  props: {
    data: {
      type: Object as PropType<{
        title: string,
        removeInspector: boolean,
        objectCounts: IObjectCount,
        inspectorsList: multiSelectorList,
        error: string,
        inspectors: { removeId: number | null, transferId: number | null },
        hasObjectsToTransfer: boolean,
        handleRequest: boolean,
      }>,
    },
  },
});
