
import {
  defineComponent,
  computed, ref, reactive,
} from 'vue';
import Container from '@/components/Container.vue';
import ViewportObserver from '@/components/ViewportObserver.vue';
import usePagination from '@/modules/usePagination';
import useRoles from '@/modules/useRoles';
import useUgns from '@/modules/useUgns';
import api from '@/api';
import { TaxPayer } from '@/types/ObjectOfTrade';
import { Worker } from '@/types/Ugnstno';
import { IObjectCount } from '@/types/Statistic';
import { multiSelectorList } from '@/components/UI/Table/types';
import { AxiosResponse } from 'axios';
import store from '@/store';
import UploadNewUsers from '@/components/Modals/UploadNewUsers.vue';
import ModalConfirmUserChanges from './components/ModalConfirmUserChanges.vue';
import ModalEditUser from './components/ModalEditUser.vue';

interface UserFilters extends Record<string, unknown> {
  role: string[],
  name: string,
}

interface SelectorEmit<T> {
  id: number, field: keyof Worker, value: T
}

export default defineComponent({
  components: {
    Container,
    ModalConfirmUserChanges,
    ModalEditUser,
    ViewportObserver,
    UploadNewUsers,
  },
  beforeRouteEnter(from, to, next) {
    if (store.state.auth.isAdmin) {
      next();
    } else {
      next({ path: '/login' });
    }
  },
  setup() {
    const showUploadNewItems = ref(false);

    const users = usePagination({
      rows: [] as Record<string, unknown>[],
      filters: {
        role: [],
        name: '',
      } as UserFilters,
      immediateFilters: [],
      delayedFilters: ['role', 'name'],
      async fetchContents() {
        const { role, name } = users.filters as UserFilters;
        const params = {
          role: role.join(',') || null,
          ...name && { name },
        };
        return (await api.user.findAll({ ...params, ...users.pagination.params })).data;
      },
    });
    users.fetch();

    const { roles } = useRoles();
    roles.fetch();

    const { ugns } = useUgns({ withCA: true });
    const listedOptions = ref([] as { value: number | undefined, label: string | undefined }[]);
    ugns.fetch().then(() => {
      listedOptions.value = ugns.options.map(({ value, name: label }) => ({ value, label }));
      listedOptions.value.shift();
    });

    const rules: any = [];

    const cols = computed(() => [
      {
        label: 'ФИО',
        width: '30%',
        display: (row: Worker) => (row.firstName && row.lastName ? (`${row.firstName} ${row.lastName}`) : row.username),
      },
      {
        label: 'УГНС',
        field: 'ugnsTno',
        field2: 'ugnsTnoCode',
        select: true,
        emit: 'change:ugns',
        width: '25%',
        options: {
          data: listedOptions.value,
          multiple: false,
          searchable: true,
          placeholder: 'Выбрать район',
          entityForCB: 'label',
          reduce: ({ label, value }: { label: string, value: number }) => ({ label, value }),
        },
      },
      {
        label: 'Роль',
        field: 'roles',
        select: true,
        emit: 'change:role',
        width: '25%',
        options: {
          data: roles.options,
          multiple: false,
          searchable: false,
          placeholder: 'Нет роли',
        },
      },
      {
        label: 'Редактировать',
        width: '10%',
        emit: 'editUser',
        component: (row: TaxPayer) => ({
          name: 'EditBtn',
          id: row.id,
        }),
      },
      {
        label: 'Удаление',
        width: '10%',
        emit: 'removeUser',
        component: (row: TaxPayer) => ({
          name: 'RemoveBtn',
          id: row.id,
          ugnsId: row.ugnsTno?.id,
        }),
      },
    ]);

    const modalInspectorManager = reactive({
      title: 'Удалить инспектора',
      removeInspector: true,
      objectCounts: {} as IObjectCount,
      inspectorsList: {} as multiSelectorList,
      inspectors: { removeId: null, transferId: null } as { removeId: number | null, transferId: number | null },
      ugns: null as number | null,
      display: false,
      hasObjectsToTransfer: false,
      handleRequest: false,
      error: '',
    });

    const editInspectorManager = reactive({
      title: 'Редактировать инспектора',
      data: {} as Worker & { password?: string },
      display: false,
      handleRequest: false,
      error: '',
    });

    const getUserObjectCounts = async (id: number) => {
      try {
        const objectCountsResponse = await api.user.countObjects(id);
        modalInspectorManager.objectCounts = objectCountsResponse.data;
        return objectCountsResponse.status;
      } catch (e) {
        modalInspectorManager.hasObjectsToTransfer = false;
        modalInspectorManager.objectCounts = { taxObjectsCount: 0, taxPayersCount: 0 };
        modalInspectorManager.error = 'Ошибка при получении данных по налогоплательщикам';
        return e.response.status;
      }
    };

    const getInspectorsListByUgns = async (ugnsId: number) => {
      const counts = [modalInspectorManager.objectCounts.taxObjectsCount, modalInspectorManager.objectCounts.taxPayersCount];
      if (counts.filter((count) => count === 0).length < 2) {
        modalInspectorManager.hasObjectsToTransfer = true;
        try {
          const inspectorsListResponse = await api.ugnstno.getWorkersById(ugnsId);
          modalInspectorManager.inspectorsList.data = inspectorsListResponse.data
            .map(({ userProfile: user }) => {
              const label = `${user.lastName} ${user.firstName} ${user.middleName}`.trim();
              return { value: user.id, label };
            })
            .filter((item) => item.value !== modalInspectorManager.inspectors.removeId);
        } catch (e) {
          modalInspectorManager.error = 'Ошибка при получении списка инспекторов';
        }
        Object.assign(modalInspectorManager.inspectorsList, {
          multiple: false,
        });
      }
    };

    const resetModalInspectorManager = () => {
      Object.assign(modalInspectorManager, {
        display: false,
        inspectors: {
          removeId: null,
          transferId: null,
        },
        error: '',
        hasObjectsToTransfer: false,
        ugns: null,
      });
      users.fetch();
    };

    const requestToRemoveUserHandler = async ({ id, ugnsId }: { id: number, ugnsId: number }) => {
      Object.assign(modalInspectorManager, {
        title: 'Удалить инспектора',
        removeInspector: true,
        error: '',
        display: true,
        inspectors: {
          transferId: null,
          removeId: id,
        },
      });

      const objectCountsResponseStatus = await getUserObjectCounts(id);
      if (objectCountsResponseStatus !== 200) return;

      await getInspectorsListByUgns(ugnsId);
    };

    const usersChangesConfirmedHandler = async (remove: boolean) => {
      modalInspectorManager.handleRequest = true;
      let response: AxiosResponse<Worker | void>;
      const updateParams = remove ? { status: 'REMOVE' } : { ugnsTno: { id: modalInspectorManager.ugns } };
      try {
        if (modalInspectorManager.hasObjectsToTransfer && Number.isInteger(modalInspectorManager.inspectors.transferId)) {
          const params = {
            removeUserId: modalInspectorManager.inspectors.removeId,
            delegatedUserId: modalInspectorManager.inspectors.transferId,
            remove,
          };
          response = await api.user.deleteUsers(params);
          if (!remove) await api.user.updateRoles(modalInspectorManager.inspectors.removeId!, updateParams);
        } else {
          response = await api.user.updateRoles(modalInspectorManager.inspectors.removeId!, updateParams);
        }
        if (response.status === 200) resetModalInspectorManager();
      } catch (e) {
        modalInspectorManager.error = 'Непредвиденная ошибка';
      } finally {
        modalInspectorManager.handleRequest = false;
      }
    };

    const changeRole = async ({ id, field, value }: SelectorEmit<Worker[keyof Worker]>) => {
      const user = (users.rows as Worker[]).find((u) => u.id === id);
      if (!user) return;

      const params = {} as Partial<Worker>;
      if (field === 'roles' && typeof value === 'string') {
        params[field] = value.length ? [value] : [];
        // params.status = value.length ? 'ACTIVE' : 'BLOCK';
      } else {
        params[field] = value as never;
      }
      try {
        const response = await api.user.updateRoles(user.id, params);
        if (response.status === 200) user[field] = value as never;
      } catch (e) {
        console.error(e);
        alert('Что-то пошло не так');
      }
    };

    const requestToChangeUgnsHandler = async ({ id, value }: SelectorEmit<{ label: string, value: number }>) => {
      const user = (users.rows as Worker[]).find((u) => u.id === id);
      if (!user) return;

      const tnoCode: number = user?.ugnsTno?.id;
      if (tnoCode === value.value) return;

      Object.assign(modalInspectorManager, {
        title: 'Смена УГНС',
        removeInspector: false,
        error: '',
        inspectors: {
          transferId: null,
          removeId: id,
        },
        display: true,
        ugns: value.value,
      });

      const objectCountsResponseStatus = await getUserObjectCounts(id);
      if (objectCountsResponseStatus !== 200) return;

      await getInspectorsListByUgns(tnoCode);
    };

    const editUserHandler = (id: number) => {
      const user = (users.rows as Worker[]).find((u) => u.id === id);
      if (!user) return;
      Object.assign(editInspectorManager, {
        display: true,
        data: user,
        error: '',
      });
    };

    const editUserConfirmed = async () => {
      editInspectorManager.handleRequest = true;
      try {
        const response = await api.user.updateRoles(
          editInspectorManager.data.id,
          {
            phone: editInspectorManager.data.phone,
            mail: editInspectorManager.data.mail,
            firstName: editInspectorManager.data.firstName,
            middleName: editInspectorManager.data.middleName,
            lastName: editInspectorManager.data.lastName,
            username: editInspectorManager.data.username,
            ...editInspectorManager.data.password?.length && { password: editInspectorManager.data.password },
          },
        );
        if (response.status === 200) {
          editInspectorManager.display = false;
          users.fetch();
        }
      } catch (e) {
        editInspectorManager.error = 'Непредвиденная ошибка';
      } finally {
        editInspectorManager.handleRequest = false;
      }
    };

    return {
      users,
      cols,
      rules,
      roles,
      changeRole,
      requestToChangeUgnsHandler,
      showUploadNewItems,
      requestToRemoveUserHandler,
      usersChangesConfirmedHandler,
      modalInspectorManager,
      editUserHandler,
      editUserConfirmed,
      editInspectorManager,
    };
  },
});
